.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-150 {
  width: 100%;
}

.h-150 {
  height: 150%;
}

.left {
  float: left;
}

.right {
  float: right;
}

.cursor {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

@font-face {
  font-family: DMSans-Regular;
  src: url("../../../public/DMSans-Regular.ttf");
}

.DMSans-Regular {
  font-family: DMSans-Regular;
}

@font-face {
  font-family: Inter-Regular;
  src: url("../../../public/Inter-Regular.ttf");
}

.Inter-Regular {
  font-family: Inter-Regular;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-24 {
  font-size: 24px;
}

.f-25 {
  font-size: 25px;
}

.f-32 {
  font-size: 32px;
}

.f-48 {
  font-size: 48px;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #000;
}

.color-emperor {
  color: #555;
}

.color-olivedrab {
  color: #5eb02b;
}

.color-mandalay {
  color: #b47818;
}

.color-purpleheart {
  color: #6b1fc0;
}

.color-cerise {
  color: #d235c3;
}

.bgcolor-white {
  background-color: #fff;
}

.bgcolor-snowflurry {
  background-color: #deffcc;
}

.bgcolor-pinklace {
  background-color: #ffccfa;
}

.bgcolor-lightgrey {
  background-color: #f7f7f7;
}

.bgcolor-sandybeach {
  background-color: #ffebcc;
}

.bgcolor-bluechalk {
  background: #f0e4ff;
}

.bgcolor-cerise {
  background: #d235c3 !important;
}

.bgcolor-silverchalice {
  background: #aca7a7;
}

tspan {
  font-size: 14px;
}

.logo {
  display: flex;
  justify-content: center;
  width: 92px;
}

.layout-menu {
  padding: 20px 0px 0px 0px;
  display: block;
  position: fixed;
  height: 100px;
}

.MuiFormControlLabel-label {
  font-family: Inter-Regular !important;
}

.layout-container {
  min-height: 100vh;
}

.layout-wrapper,
.layout-container {
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
}

.layout-page {
  margin-left: 110px;
  flex-basis: 100%;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  max-width: 100%;
}

.layout-pag {
  margin-left: 70px;
  width: 100%;
}

.layout-pagii {
  width: 100%;
}

.layout-navbar {
  position: relative;
  padding-top: 0.25rem;
  padding-bottom: 0.2rem;
  height: 70px;

  flex-wrap: nowrap;
  color: #697a8d;
  z-index: 2;
}

.layout-navbar.navbar-detached {
  width: calc(100% - (1.625rem * 2));
  margin: 0.75rem auto 0;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
}

.menu-vertical .menu-inner {
  flex-direction: column;
  flex: 1 1 auto;
}

.menu-vertical .menu-inner>.menu-item .menu-link {
  margin: 0rem 1rem;
}

.menu-vertical .menu-item .menu-link,
.menu-vertical .menu-block {
  padding: 0.625rem 1rem;
}

.menu-vertical .menu-header {
  margin: 1rem 0 0.5rem 0;
  padding: 0.625rem 2rem 0.625rem 2rem;
}

.bg-menu-theme .menu-header {
  position: relative;
}

.bg-menu-theme .menu-header:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1.1875rem;
  width: 1rem;
  height: 1px;
  transition: all 0.3s ease-in-out;
}

.bg-menu-theme {
  background-color: #ffffff !important;
  color: #c4cdd5;
}

.bg-menu-theme .menu-link,
.bg-menu-theme .menu-horizontal-prev,
.bg-menu-theme .menu-horizontal-next {
  color: #c4cdd5;
}

.bg-menu-theme .menu-link:hover,
.bg-menu-theme .menu-link:focus,
.bg-menu-theme .menu-horizontal-prev:hover,
.bg-menu-theme .menu-horizontal-prev:focus,
.bg-menu-theme .menu-horizontal-next:hover,
.bg-menu-theme .menu-horizontal-next:focus {
  color: #fff;
}

.bg-menu-theme .menu-header:before {
  background-color: #a1acb8 !important;
}

.menu-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5rem;
  padding: 0;
  list-style: none;
  width: 92px;
}

.content-wrapper {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
}

.layout-menu-100vh .layout-menu,
.layout-menu-100vh .layout-overlay {
  height: 100vh !important;
}

.foot {
  margin-top: 4rem;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  color: #2b3b41;
}

.sidebar-wrap {
  width: 92px;
  border-radius: 50px;
  border: 0.5px solid #aca7a7;
  background: #d235c3;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.sidebar-wrap i {
  color: #fff;
}

.header .profile-box {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.header .nav-box {
  margin: 6px 6px 0px;
  width: 40px;
  height: 40px;
  background: #f9f9f9;
  border: 0.5px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  font-size: 18px;
  font-weight: 700;
}

.menu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 20px;
}

.active .menu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 56px;
  height: 56px;
  border-radius: 20px;
}

.sidebar-wrap::-webkit-scrollbar {
  display: none;
}

.logo-box {
  position: absolute;
  height: 90px;
  background: #d235c3;
  border-radius: 40px 40px 0px 0px;
  margin: -1px;
  padding-top: 17px;
  z-index: 9;
}

.header .ham-icon {
  font-size: 30px;
  margin-top: 10px;
  margin-left: -4px;
}

.cursor {
  cursor: pointer;
}

@media (min-width: 791px) {
  .ham-icon {
    display: none;
  }
}

@media (max-width: 790px) {
  .layout-page {
    margin-left: 0px;
  }

  .header .location {
    display: none;
  }
}

.layout-login {
  display: block;
  /* width: 1166px;
  height: 520px; */
  flex-shrink: 0;
  background: #deffcc;
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;
  padding: 20px;
}

.login_head {
  /* width: 387px; */
  padding-top: 100px;
}

.login_headLayout {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.btn_signup {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #000;
  background: #fff;
}

.login_userLayout {
  /* width: 454px;
  height: 648px; */
  flex-shrink: 0;
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
  margin-top: -70px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

/* .login_labelText {
  color: #000;
  font-family: DM Sans-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} */
/* .login_inputText {
  color: #555;
  font-family: Inter-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
} */
.textbox_layout {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 316px;
  border-radius: 10px;
  border: 0.5px solid #aca7a7;
  /* width: 406px;
  height: 40px; */
}

.link_text {
  text-decoration-line: underline;
  /* padding-left: 150px; */
}

.btn_login {
  display: flex;
  /* width: 406px;
  height: 40px; */
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #d235c3;
}

/* .btn_loginText {
  color: #fff;
  font-family: DMSans-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1px;
} */
.text-custom-contain-log {
  border: none;
  cursor: pointer;
}

.box-dash {
  padding: 15px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.bg-lightGreen {
  background-color: #deffcc;
}

.box-title {
  color: #555;
  font-family: DMSans-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.box-text {
  color: #5eb02b;
  font-family: DMSans-Regular;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.box-text1 {
  color: #000;
  font-family: DMSans-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tableRow {
  stroke-width: 0.5px;
  stroke: #aca7a7;
}

.tableHead {
  color: #555;
  font-family: DMSans-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  stroke-width: 0.5px;
  stroke: #aca7a7;
}

.tableText {
  color: #000;
  font-family: Inter-Regular;
  /* font-size: 16px; */
  font-style: normal;
  /* font-weight: 500; */
  line-height: 72px;
  /* 450% */
}

.tableText-active {
  color: #000;
  font-family: Inter-Regular;
  /* font-size: 16px; */
  font-style: normal;
  /* font-weight: 500; */
  line-height: 72px;
  /* 450% */
  background-color: #deffcc;
}

.tableText-inactive {
  color: #000;
  font-family: Inter-Regular;
  /* font-size: 16px; */
  font-style: normal;
  /* font-weight: 500; */
  line-height: 72px;
  /* 450% */
  background-color: #ffe6e6;
}

.eclipse {
  border-radius: 30px;
  border: 1px solid #000;
  background: #fff;
}

.img-rightside {
  float: right;
}

.template-layout {
  /* width: 1512px; */
  padding-left: 30px;
  background: #fff;
}

.template-boxdefault {
  border-radius: 24px;
  border: 0.5px solid #5eb02b;
}

.template-box {
  border-radius: 24px;
  border: 0.5px solid #aca7a7;
  background: #fff;
}

.line {
  position: absolute;
  top: 400px;
  /* width: 236px;
  height: 0.5px; */
}

.text-area {
  outline: none;
  /* width: 100%; */
  border: none;
}

.ellipse-serach {
  border-radius: 35px;
  background: #f9f9f9;
  /* width: auto; */
  border: none;
}

.ellipse-dropdown {
  display: flex;
  height: 40px;
  width: 100%;
  padding: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 0.5px solid #cbcbcb;
}

.individualqr-layout {
  position: relative;
  border-radius: 18px;
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
  margin-left: 100px;
  padding-top: 15px;
}

.qr-layout {
  border-radius: 16px;
  border: 0.5px solid #cbcbcb;
  /* width: 120px;
  height: 120px; */
  margin-left: -20px;
}

.box-individual {
  border-radius: 40px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.box-half {
  padding: 15px;
  /* position: absolute; */
  bottom: 0px;
  border-radius: 0px 0px 40px 40px;
  /* width: 278px;
  height: 92px; */
}

.active {
  border-radius: 0px 0px 18px 18px;
}

.btn-download {
  border-radius: 30px !important;
  text-transform: capitalize !important;
  min-width: 140px !important;
  height: 45px;
}

.status-active {
  border-radius: 5px;
  padding: 7px 14px;
  background: #deffcc;
}

.status-inactive {
  padding: 7px 22px 7px 14px;
  border-radius: 5px;
  background: #ffe6e6;
}

.status-drafted {
  border-radius: 5px;
  padding: 7px 55px 7px 14px;
  background: #fff8d4;
}

.btn-export {
  border-radius: 30px;
}

.box_billing {
  position: relative;
  border-radius: 25px;
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.billing-plan {
  position: absolute;
  top: 0px;
  right: 24px;
  border-radius: 0px 0px 18px 18px;
  display: flex;
  /* width: 102px;
  height: 29px; */
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.analytics-layout {
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.ui.input {
  font-size: 10px;
  box-shadow: none;
}

.ui .search-bar {
  box-shadow: none;
  height: 25px;
}

.ui.label,
.ui.message,
.ui .search-bar {
  border-radius: 3px;
}

.ui.input {
  font-size: 1em;
}

.ui.input {
  position: relative;
  font-weight: 400;
  font-style: normal;
  display: inline-flex;
  color: rgba(0, 0, 0, 0.87);
}

.search-bar {
  width: 100%;
  height: 48px;
  float: left;
  margin: 0 0 12px;
}

.W100 {
  width: 100%;
}

.ui.icon.input>input {
  padding-right: 2.67142857em !important;
}

.ui.input>input {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.1428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  border: none;
  border-radius: 19px;
  background: #f9f9f9;
  padding-left: 10px;
  height: 40px;
}

.ui.icon.input>i.circular.icon {
  top: -4px;
  right: 0.6em;
}

.ui.icon.input>i.link.icon {
  cursor: pointer;
}

.ui.icon.input>i.icon {
  cursor: default;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 0;
  right: 0;
  margin: 0;
  height: 100%;
  width: 2.67142857em;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
}

i.inverted.bordered.icon,
i.inverted.circular.icon {
  color: #050000 !important;
}

i.circular.inverted.icon {
  border: none;
  box-shadow: none;
}

i.inverted.icon {
  color: #fff;
  font-size: 18px;
}

i.circular.icon {
  border-radius: 10px !important;
  line-height: 1 !important;
  padding: 0.5em 0 !important;
  box-shadow: inset 0 0 0 0.1em rgb(0 0 0 / 10%);
  width: 2em !important;
  height: 2em !important;
}

i.link.icon,
i.link.icons {
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.1s ease;
}

i.icon,
i.icons {
  font-size: 1em;
}

i.icon {
  display: inline-block;
  opacity: 1;
  margin: 0 0.25rem 0 0;
  width: 1.18em;
  height: 1em;
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
  /* speak: none;
  font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.dropdown-Text-bar {
  width: 100%;
}

.ui.icon.input>i.circular.icon1 {
  top: -0.95em;
  left: -0.5em;
}

.ui.input>.drop-text {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.1428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding-left: 155px;
  height: 40px;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #d235c3 !important;
}

.custom-table {
  white-space: nowrap;
  /* overflow-x: scroll; */
}

/* .custom-table::-webkit-scrollbar {
  margin: 20px;
  width: 5px;
  height: 5px;
  background: #d3d3d3;
  border-radius: 20px;
} */
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #d3d3d3;
}

::-webkit-scrollbar-thumb {
  background: #979a98;
}

::-webkit-scrollbar-thumb:hover {
  background: #e5e8e6;
}

.custom-table::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

.custom-table::-webkit-scrollbar-track {
  background: #d3d3d3;
}

.custom-table::-webkit-scrollbar-thumb {
  background: #979a98;
}

.custom-table::-webkit-scrollbar-thumb:hover {
  background: #e5e8e6;
}

.tab ul {
  list-style: none;
  margin-left: 0px;
  display: inline-block;
  overflow-y: hidden;
  white-space: nowrap;
  overflow: auto;
  max-width: 100%;
  padding-left: 0px;
}

.tab ul::-webkit-scrollbar {
  display: none;
}

.tab li {
  display: inline-block;
  /* padding: 10px 5px; */
  margin-right: 10px;
  cursor: pointer;
}

.tab .current {
  color: #000;
  font-family: "DMSans-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.custom-shape {
  width: 10px;
  height: 9px;
  background-color: #d235c3;
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
}

.border-shape {
  border-radius: 10px;
  background-color: #d235c3;
  height: 4px;
}

.create {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.option {
  display: inline-flex;
  justify-content: space-between;
  border-radius: 30px;
  border: 1px solid #000;
  background: #fff;
  padding: 8px;
  width: 120px;
  align-items: center;
}

.Qr-Analysis {
  margin-left: 20px;
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.recharts-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.progress-box {
  padding: 15px;
  border-radius: 16px;
  border: 0.5px solid #aca7a7;
}

.progress-box p {
  margin-bottom: 4px;
}

.cards {
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
}

.input-label {
  color: #555;
  text-align: center;
  font-family: Inter-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label {
  color: #000;
  font-family: "DMSans-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
}

.custom-horizontal-input {
  padding: 10px;
  height: 40px;
  width: 100%;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid #aca7a7;
  color: #555;
  font-family: Inter-Regular;
}

.input-style-area {
  padding: 10px;
  width: 100%;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid #aca7a7;
  color: #555;
  font-family: Inter-Regular;
}

.css-1asqsdz-MuiSwitch-root .MuiSwitch-switchBase {
  color: #d235c3;
}

/* Accordion.css */

.accordion-header {
  cursor: pointer;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.link {
  text-decoration: none;
  color: #000;
}

.accordion-header.active {
  background-color: #ddd;
}

.accordion-content {
  padding: 10px;
  border: 1px solid #ccc;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-content .open {
  max-height: 200px;
  /* Adjust the max height as needed */
}

.feedback {
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.feedback img {
  width: 100%;
}

.check-layout {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-left: 180px;
}

.t-box {
  border: 1px solid #b47818;
  background: #fff;
}

.cta-option {
  border-radius: 18px;
  background: #deffcc;
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
}

.text-update {
  padding-left: 40px;
  padding-top: 10px;
}

.text-sample {
  margin-left: -150px;
}

.performance-layout {
  position: relative;
  border-radius: 40px;
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.idividual-active {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px 0px 18px 18px;
  background: #deffcc;
  top: 0px;
  right: 24px;
}

.groupqr-layout {
  position: relative;
  border-radius: 18px;
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
  padding-top: 15px;
  padding: 13px;
}

.custom-round-spinner {
  border-top-color: #28a745 !important;
  /* Green color for the spinner */
  width: 3rem;
  /* Adjust size */
  height: 3rem;
  /* Adjust size */
  border-width: 0.4rem;
  /* Adjust border width */
  border-radius: 50%;
  /* Ensures it stays round (optional, but it's good for assurance) */
}

.btn-preview {
  width: 325px;
  padding: 10px;
  margin: 39px 0;
}

.drag-drop-box {
  text-align: left !important;
}

.drag-text {
  border-style: dashed;
  width: 265px;
  border-color: #cccccc;
  padding: 14px;
  justify-self: center !important;
}

.file-info {
  justify-self: center !important;
}

.btn-upload {
  background-color: #d235c3;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 26px;
  cursor: pointer;
  width: 133px;
  margin: 0 169px;
}

.custom {
  padding: 8px;
  width: 228px;
}

.input-t {
  height: 42px;
  border-radius: 10px;
  text-align: center;
  border-color: #bdb9b9;
}

.btn-text {
  padding: 9px;
}

.cta-option {
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
  background-color: #f8f9fa;
  cursor: pointer;
  border-radius: 5px;
}

.selected-cta {
  /* padding: 10px; */
  /* border: 1px solid #28a745; */
  background-color: #d4edda;
  /* Green background for selected */
  color: #155724;
  border-radius: 5px;
  font-weight: bold;
}

.cta-icon {
  width: 20px;
  /* Adjust the size of the icon */
  height: 20px;
  /* Adjust the size of the icon */
}

.cta-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cta-item h3 {
  margin-bottom: 0;
  /* Remove bottom margin to ensure icon and text align properly */
}

.tags {
  border-radius: 35px;
  padding: 12px;
}

.bg-light-green {
  background-color: #d3f9d8;
  /* Light green background */
}

.outline-primary-button {
  background-color: #d3f9d8;
  /* Light green background */
  /* height: 72px; */
  border-radius: 35px;
  height: 39px;
}

.outline-primary-button {
  color: black;
  border: 1px solid #000 !important;
  border-radius: 26px !important;
}

.btn-link {
  background-color: #00000080 !important;
  font-weight: bold;
  border-radius: 32px;
  border: 1px solid #acacac;
  color: black !important;
  text-decoration-line: none;
}

.form-label-h {
  text-align: center;
}
.newlinks{
  
  margin-left: 250px;
}

.available-billing-plans .card {
  /* border: 1px solid #ddd; */
  transition: transform 0.2s;
}

.available-billing-plans .card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.available-billing-plans h2 {
  font-size: 2rem;
  font-weight: bold;
}

.available-billing-plans .btn-primary {
  /* background-color: #007bff; */
  background-color: #d235c3;
  border: none;
}

.available-billing-plans .btn-primary:hover {
  background-color: #0056b3;
}

.days {
  font-size: 15px;
  font-weight: 500;
}

.card-custom {
  max-width: 500px;
  /* Reduces the width of the card */
  margin: 0 15px;
  /* Adds spacing on the left and right */
  border-radius: 31px;
}

.poster-container {
  margin-bottom: 20px;
  text-align: center;
}

/* .poster-content {
  max-width: 100%;
  height: auto;
  max-height: 300px;
  overflow: hidden;
} */

.poster-content img {
  max-width: 100%;
  max-height: 300px;
  /* Ensures the image fits within the container */
  object-fit: contain;
}

/* Steps.css */

/* Ensure steps stay in one row even on smaller screens */
.steps-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  /* Prevent wrapping */
}

.steps-row .mb-3 {
  text-align: center;
  flex: 1;
  /* Allow columns to evenly distribute across the row */
}

/* Add a custom class for the step line to adjust spacing */
.step-line {
  /* width: 30px; 
  height: 1px; */
  background-color: #ccc;
}

/* Adjustments for small screens */
@media (max-width: 576px) {
  .steps-row .col-xs-4 {
    flex: 1;
    /* Each step takes equal space */
    margin-bottom: 0;
    /* Remove space between steps */
  }

  .steps-row .col-xs-1 {
    display: none;
    /* Hide the line on mobile */
  }

  /* Adjust the text size for mobile */
  .Inter-Regular.f-16 {
    font-size: 14px;
  }

  /* Center the step numbers (optional for mobile view) */
  .steps-row .mb-3 img {
    /* margin: 0 auto; */
    margin: 0 auto;
    width: 20px;
  }

}

/* Larger devices */
@media (min-width: 768px) {
  .steps-row .col-md-3 {
    flex: 1;
    /* Ensure steps take equal space */
  }

  .steps-row .col-md-1 {
    width: 5%;
  }

  .Inter-Regular.f-16 {
    font-size: 16px;
  }

}

/* Large screens (e.g., tablets and up) */
@media (min-width: 992px) {
  .steps-row .col-md-3 {
    flex: 1;
    /* Keep steps evenly distributed */
  }
}

/* Media Queries for Responsiveness */

/* For devices with width 1200px or below */
@media (max-width: 1200px) {
  .form-container {
    padding: 10px;
  }

  .box-main {
    /* width: 100%;
    height: auto; */
    width: 436px;
    height: 114px;
    /* padding: 11px; */
    border: 1px solid #0000001a;
    margin: 0 10px;
  }

  .input {
    width: 100%;
  }

  .drag-drop-box {
    width: 100%;
  }

  .btn-upload,
  .btn-add-url {
    width: 100%;
    margin: 0;
  }

  .box-container {
    display: flex;
    flex-direction: column;
  }

  .box {
    margin-bottom: 10px;
  }

  .input-box {
    width: 100%;
  }

  .btn-download {
    /* width: 100%; */
  }

  .steps-row .mb-3 img {
    /* margin: 0 auto; */
    margin: 0 auto;
    width: 20px;
  }
}

@media (max-width: 1800px) {
  .steps-row .mb-3 img {
    /* margin: 0 auto; */
    margin: 0 auto;
    width: 20px;
  }

  .btn-upload {
    margin: 0;
    padding: 0;
    /* width: 100%; */
  }

}

/* For devices with width 768px or below (Tablets) */
@media (max-width: 768px) {
  .box-main {
    width: 100%;
    margin-bottom: 10px;
  }

  .drag-drop-box {
    width: 100%;
    padding: 10px;
  }

  .input-box {
    padding: 5px;
    margin: 0 10px;
  }

  .input {
    width: 100%;
    font-size: 14px;
  }

  .btn-upload,
  .btn-add-url {
    width: 100%;
    padding: 10px;
    margin: 0;
  }

  .form-container {
    padding: 10px;
  }

  .steps-row .mb-3 img {
    /* margin: 0 auto; */
    margin: 0 auto;
    width: 20px;
  }
}

/* For devices with width 480px or below (Mobile devices) */
@media (max-width: 480px) {
  .form-container {
    padding: 5px;
  }

  .box-main {
    width: 100%;
    margin-bottom: 10px;
  }

  .input-box {
    margin: 0 5px;
  }

  .input {
    font-size: 14px;
  }

  .btn-upload,
  .btn-add-url {
    width: 100%;
    padding: 10px;
    margin: 0;
  }

  .radio {
    flex-direction: column;
    align-items: flex-start;
  }

  .file-info {
    font-size: 12px;
  }

  .drag-text {
    width: auto;
  }
}

/* h3, span, img {
  pointer-events: auto;
} */

.template-item {
  margin-bottom: 20px;
  /* Add space between rows */
}

.template-item img {
  border-radius: 5px;
  /* Optional: Add rounded corners to images */
}

.up {
  margin: 0 16px;
}

.template-item {
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
  /* width: 145px; */
  margin: 0 14px;
  padding: 14px !important;
}

/* .template-item:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
} */

.img-fluid {
  height: 103px;
}

.box-main {
  /* width: 100%;
  height: auto; */
  width: 436px;
  height: 114px;
  /* padding: 11px; */
  border: 1px solid #0000001a;
  margin: 0 10px;
}

.group-btn {
  font-size: 14px;
  padding: 10px;
}

.group-btn-t {
  border: none;
}

.modal-content {
  /* position: relative; */
  /* display: flex
; */
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  border-radius: 31px;
  z-index: 1000px;
  /* width: 566px; */
}

.modal-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  /* z-index: 1000; */
  overflow: auto;
}


.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: flex-end; */
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap)* .5);
  /* background-color: var(--bs-modal-footer-bg); */
  /* border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color); */
  /* border-bottom-right-radius: var(--bs-modal-inner-border-radius); */
  /* border-bottom-left-radius: var(--bs-modal-inner-border-radius); */
}

.btn-modal {
  background-color: #d436c5;
  border: 1px solid;
}

.modal.show .modal-dialog {
  transform: none;
  /* margin-top: 203px; */
}

.form-select {
  border-radius: 33px;
}

.add {
  background-color: #d235c3;
  border: none;
  border-radius: 22px;
  width: 132px;
  height: 43px;
}

.event {
  background-color: #d235c3;
  border: none;
  height: 42px;
  margin: 0px 0;
  width: 131px;
  border-radius: 25px;

}

.template-item img {
  width: 100%;
  /* Full width */
  /* height: auto;  */
  /* max-height: 200px; */
  max-height: 451px;
  object-fit: cover;
  /* Cover the area without distortion */
}

.template-item h5 {
  text-align: center;
  /* Center the text */
  margin-top: 10px;
  /* Space above the text */
}

.input-field {
  width: 100%;
  height: 35px;
  border: 1px solid #00000047;
  border-radius: 4px;
}

.btn-bg-color {
  background-color: #d435c5;
  border: none;
  color: white;
  width: 100px;
  height: 39px;
  border-radius: 24px;
}

.location-table {

  width: 990px;
}

/* .col-lg-4 {
  flex: 0 0 auto;
  width: 21.333333%;
}

.col-lg-8 {
  flex: 0 0 auto;
  width: 77.666667%;
} */

/* @media (min-width: 1280px) and (max-width: 1280px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 69.666667%;
}
  .col-lg-4 {
    flex: 0 0 auto;
    width: 30.333333%;
}
}
@media (min-width: 1920px) and (max-width: 1920px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 75.666667%;
}
  .col-lg-4 {
    flex: 0 0 auto;
    width: 24.333333%;
}
}

@media (min-width: 1024px) and (max-width: 1110px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 60.666667%;
  }
.col-lg-4 {
  flex: 0 0 auto;
  width: 39.333333%;
}
.drag-text {
  border-style: dashed;
  width: 100%;
  border-color: #cccccc;
  padding: 14px;
  justify-self: center !important;
}
} */


@media (min-width: 992px) and (max-width: 1000px) {
  .file-input {}

  .drag-text {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
    width: auto;
  }

  .form-container-de {
    width: auto;
  }

  .preview-container {
    width: auto;
    margin: 0;
  }

  /* .col-lg-4 {
  flex: 0 0 auto;
  width: 39.333333%;
}
.col-lg-8 {
  flex: 0 0 auto;
  width: 60.666667%;
} */
}

@media (min-width: 1001px) and (max-width: 1270px) {
  .drag-text {
    width: auto;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 39.333333%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 60%;
  }

}

@media (min-width: 1271px) and (max-width: 1650px) {
  .drag-text {
    width: auto;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 30%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 70%;
  }
}


.location-btn {
  color: white;
  width: 103px;
  border: none;
  height: 34px;
  border-radius: 18px;
  background-color: #d440c6;
}

.custom-table {
  overflow-x: auto !important;
}
@media (max-width: 768px) {
  .custom-table table {
    width: 100%;
    overflow-x: auto;
    display: block;
  }

  .custom-table th, .custom-table td {
    font-size: 12px;
    padding: 8px;
  }

  .custom-table .tableHead th {
    font-size: 14px;
  }

  .custom-table .tableText {
    word-wrap: break-word;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .custom-table td {
    padding: 8px;
    text-align: left;
  }

  .custom-table .mt-3 {
    margin-top: 8px;
  }
}

@media (max-width: 480px) {
  .custom-table th, .custom-table td {
    font-size: 10px;
    padding: 6px;
  }

  .custom-table .tableText {
    font-size: 10px;
    max-width: 120px;
  }

  .custom-table .tableHead th {
    font-size: 12px;
  }
}
.error{
  color: red;
}
/* .css-sneppl-MuiFormControl-root {

  width: auto !important;
} */

.bottom-content {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.play-btn {
  position: absolute;
  bottom: 80px; 
  left: 50%;
  transform: translateX(-50%);
}

.test {
  text-align: left; 
  display: block;
}
.dollar{
  color: #D235C3;
  
}
